const apiBaseUrl = '//client.api.booksound.cn'
// const apiBaseUrl = 'http://127.0.0.1:5002'
// const apiBaseUrl = 'http://121.40.179.17:5002'
const mp_appId = 'wx5f5a729f30029e2c'//书音公众号appId
const open_appId = 'wx42c8e238fb92b2e3'//微信开发平台网站应用appId
// const open_appId = 'wx5a5946c3a8aa4297'

module.exports = {
  apiBaseUrl,
  mp_appId,
  open_appId
}
