<template>
  <div class="main-container message-board">
    <van-nav-bar :title="`${botInfo.libraryName}留言板`" />
    <van-form
      ref="ruleForm"
      :model="formData"
      validate-first
      @submit="submit"
      label-align="right"
      v-if="!isPosted"
    >
      <van-field
        name="title"
        label="留言主题"
        v-model="formData.title"
        placeholder="请输入留言主题"
        required
        center
        size="large"
        right-icon="edit"
        :rules="[
          {
            required: true,
            message: '请输入留言主题',
          },
        ]"
        maxlength="50"
      />
      <van-field
        name="content"
        label="留言内容"
        v-model="formData.content"
        placeholder="请输入留言内容"
        required
        size="large"
        right-icon="edit"
        rows="5"
        type="textarea"
        autosize
        maxlength="500"
        show-word-limit
        :rules="[
          {
            required: true,
            message: '请输入留言内容',
          },
        ]"
      />
      <van-field
        name="contacterName"
        label="您的姓名"
        v-model="formData.contacterName"
        placeholder="请输入您的姓名"
        required
        center
        size="large"
        right-icon="edit"
        :rules="[
          {
            required: true,
            message: '请输入您的姓名',
          },
        ]"
        maxlength="20"
      />
      <van-field
        name="contacterEmail"
        label="您的邮箱"
        v-model="formData.contacterEmail"
        placeholder="请输入您的邮箱"
        center
        size="large"
        right-icon="edit"
        :rules="[
          {
            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message: '请输入正确的邮箱格式',
            validateEmpty: false,
          },
          {
            validator: validateEmailAndPhone,
            trigger: 'onBlur',
            message: '邮箱和电话至少填写一个',
          },
        ]"
        maxlength="100"
      />
      <van-field
        name="contacterPhone"
        label="您的电话"
        v-model="formData.contacterPhone"
        placeholder="请输入您的电话"
        center
        size="large"
        right-icon="edit"
        type="tel"
        :rules="[
          {
            pattern:
              /^(13[0-9]|14[5-9]|15[0-3,5-9]|16[6]|17[0-8]|18[0-9]|19[8,9])\d{8}$/,
            message: '目前只支持中国大陆的手机号码',
            validateEmpty: false,
          },
          {
            validator: validateEmailAndPhone,
            trigger: 'onBlur',
            message: '邮箱和电话至少填写一个',
          },
        ]"
        maxlength="20"
      />
      <div class="btn-box">
        <van-button type="primary" round block native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>
    <div v-else class="success-box">
      <p><van-icon name="success" /></p>
      <p>感谢您的留言</p>
      <div v-if="isWeiXin" class="wx-subscribe-box">
        <p>
          为了方便您及时获知回复结果，请点击下方链接订阅微信一次性消息通知。一旦工作人员对您的留言进行回复，将会通过微信第一时间通知到您！
        </p>
        <van-button type="primary" round block @click="goToSubscribe"
          >订阅消息通知</van-button
        >
      </div>
      <div v-else class="subscribe-qrcode-box">
        <img
          :src="'data:image/png;base64,' + qrcode_data"
          alt="微信扫码订阅消息通知"
        />
        <h3>微信扫码订阅消息通知</h3>
        <p>
          为了方便您及时获知回复结果，请用『微信扫一扫』扫描上方二维码订阅一次性消息通知。一旦工作人员对您的留言进行回复，将会通过微信第一时间通知到您！
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import * as api from "@/api";
import { mapGetters } from "vuex";
import { getUrlCode, isWeiXin } from "@/utils/utils";
export default {
  name: "MessageBoard",
  components: {},
  data() {
    return {
      isWeiXin: true,
      isPosted: false,
      formData: {
        title: "",
        content: "",
        contacterName: "",
        contacterEmail: "",
        contacterPhone: "",
      },
      subscribeUrl: "",
      qrcode_data: "",
    };
  },
  computed: {
    ...mapGetters(["botInfo"]),
  },
  created() {
    this.isWeiXin = isWeiXin();
  },
  methods: {
    validateEmailAndPhone(value) {
      console.log(value);
      if (
        this.formData.contacterEmail == "" &&
        this.formData.contacterPhone == ""
      ) {
        return false;
      } else {
        return true;
      }
    },
    submit() {
      let _this = this;
      this.$refs["ruleForm"].validate().then(() => {
        api.addMessageBoard(this.formData).then((res) => {
          _this.isPosted = true;
          _this.subscribeUrl = res.subccribeUrl
          _this.qrcode_data = res.qrCode_base64
        });
      });
    },
    goToSubscribe(){
      window.location.replace(this.subscribeUrl)
    }
  },
};
</script>
<style lang="less" scoped>
.message-board {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  & .van-form {
    flex: 1;
    overflow-y: auto;
    & :deep(.van-field__label) {
      margin-right: 12px;
      width: 5em;
    }
    & .btn-box {
      padding: 24px;
    }
  }
  & .success-box {
    text-align: center;
    padding: 40px 20px;
    font-size: 18px;
    & .van-icon {
      font-size: 48px;
      color: #07c160;
      margin-bottom: 20px;
    }
  }
  & .wx-subscribe-box {
    padding: 40px 0;
    & p {
      margin-bottom: 20px;
      text-align: left;
      font-size: 14px;
      color: #e6a23c;
      padding: 16px;
      background: #fdf6ec;
    }
  }
}
@media screen and (min-width: 680px) {
  .message-board {
    width: 680px;
    margin: 20px auto;
    border-radius: 16px;
    background: #fff;
    height: initial;
    & .van-nav-bar {
      line-height: 56px;
      & :deep(.van-nav-bar__content) {
        height: 56px;
      }
      & :deep(.van-nav-bar__title) {
        font-size: 18px;
      }
    }
    & .van-form {
      & .btn-box {
        padding: 24px;
      }

      & .van-cell {
        line-height: 1.5em;
        padding: 12px 18px;
        font-size: 16px;
        & :deep(.van-field__right-icon .van-icon) {
          font-size: 18px;
        }
      }
      & :deep(.van-field__label) {
        margin-right: 12px;
        width: 6.2em;
        font-size: 16px;
      }
      & :deep(.van-field__control) {
        line-height: 1.5em;
      }
      & :deep(.van-cell__title) {
        font-size: 16px;
      }
      & :deep(.van-cell__value) {
        font-size: 14px;
      }
      & :deep(.van-field__error-message) {
        font-size: 14px;
      }
      & :deep(.van-field__word-limit) {
        font-size: 14px;
        line-height: 1em;
      }
    }
    & .success-box {
      padding: 40px;
      font-size: 18px;
      & .van-icon {
        font-size: 48px;
        margin-bottom: 20px;
      }
    }
  }
  & .subscribe-qrcode-box {
    padding-top: 30px;
    & img {
      display: block;
      width: 180px;
      height: 180px;
      background:#efefef;
      margin: 0 auto 20px;
    }
    & p {
      text-align: left;
      font-size: 14px;
      color: #e6a23c;
      padding: 16px;
      background: #fdf6ec;
      margin-top: 20px;
    }
  }
}
</style>
