import axios from './axios';
//微信授权登录
export const loginByWechat = (code, botCode, pcOrMobile) => {
  return axios.post('WebLogin', { code, botCode, pcOrMobile }).then(res => res.data)
}

export const refreshToken = (userId, refreshToken) => {
  return axios.post('WebLogin/RefreshToken', { userId, refreshToken }).then(res => res.data)
}
//获取bot信息
export const getBotInfo = () => {
  return axios.get('WebLogin/BotInfo').then(res => res.data)
}

//获取会话记录
export const getLastChatSessions = () => {
  return axios.get('WebChat/Sessions').then(res => res.data)
}
//获取欢迎语
export const getWelcomeMsg = () => {
  return axios.get('WebChat/Welcome').then(res => res.data)
}
//获取会话详情
export const getChatSessionDetail = (sessionId, page, pageSize) => {
  return axios.get(`WebChat/SessionDetail/${sessionId}`, { params: { page, pageSize } }).then(res => res.data)
}
//点赞
export const msgThumbsUp = (msgId) => {
  return axios.post(`WebChat/MessageFeedback/ThumbsUp/${msgId}`).then(res => res.data)
}
//取消点赞
export const msgThumbsCancel = (msgId) => {
  return axios.post(`WebChat/MessageFeedback/ThumbsCancel/${msgId}`).then(res => res.data)
}
//点踩并反馈
export const msgThumbsDown = (msgId, data) => {
  console.log(data)
  return axios.post(`WebChat/MessageFeedback/ThumbsDown/${msgId}`, data).then(res => res.data)
}
//阿里云语音识别调用的token
export const getAlnsToken = () => {
  return axios.get('WebChat/ANLS/Token').then(res => res.data)
}
//留言板新增留言
export const addMessageBoard = data => {
  return axios.post('WebChat/MessageBoard', data).then(res => res.data)
}
//留言板详情
export const getMessageBoardDetail = id => {
  return axios.get(`WebChat/MessageBoard/${id}`).then(res => res.data)
}

export const sendSmsCaptcha = cellphone => {
  return axios.post('WebLogin/SMSVeriCode', { cellphone }).then(res => res.data)
}

export const updateCellphone = (cellphone, veriCode) => {
  return axios.put('WebLogin/UserInfo/Cellphone', { cellphone, veriCode }).then(res => res.data)
}

