export function getUrlCode() {
  // 截取url中的code方法
  var url = location.search;
  // this.winUrl = url;
  var theRequest = new Object();
  if (url.indexOf("?") != -1) {
    var str = url.substr(1);
    var strs = str.split("&");
    for (var i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
    }
  }
  return theRequest;
}

//判断是否是微信浏览器的函数
export function isWeiXin() {
  //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
  var ua = window.navigator.userAgent.toLowerCase();
  //通过正则表达式匹配ua中是否含有MicroMessenger字符串
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true;
  } else {
    return false;
  }
}
export function isIOs() {
  var UA = window.navigator.userAgent;
  console.log(UA)
  if(/iPad/i.test(UA) || /iPod|iPhone/i.test(UA)) {
    return true
  } else {
    return false
  }
}
//防抖函数
export function debounce(func,delay){//函数的防抖，该函数的返回值是一个函数
  let timer = null;
  return function (...args){
    if(timer) clearTimeout(timer); //清上一次的计数器

    timer = setTimeout(() =>{
      func.apply(this,args); //调用func ，同时改变它的this 为 window
    },delay)
  }//return
}
