import * as types from './type.js'

export default {
  setCurrentUser: ({ commit }, user) => {
    commit(types.SETCURRENTUSER, user)
  },
  setToken: ({ commit }, token) => {
    commit(types.SETTOKEN, token)
  },
  setBeforeLogin: ({ commit }, beforeLogin) => {
    commit(types.SETBEFORELOGIN, beforeLogin)
  },
  setBotCode: ({ commit }, botCode) => {
    commit(types.SETBOTCODE, botCode)
  },
  setBotInfo: ({ commit }, botInfo) => {
    commit(types.SETBOTINFO, botInfo)
  }
}
