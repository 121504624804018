<template>
  <div class="feedback-wrap">
    <h3>信息反馈</h3>
    <van-checkbox-group v-model="formData.feedbackType" shape="square">
      <van-checkbox :name="i" v-for="(w, i) in enumFeedbackType" :key="i">{{
        w
      }}</van-checkbox>
    </van-checkbox-group>
    <van-field
      v-model="formData.betterSuggested"
      type="textarea"
      placeholder="您认为更优的答案应该是什么"
      ref="input"
    >
    </van-field>
    <div class="foot-btn">
      <van-button size="mini" @click="close">取消</van-button>
      <van-button
        type="primary"
        @click="submit"
        size="mini"
        :disabled="
          formData.feedbackType.length == 0 && !formData.betterSuggested
        "
        >提交</van-button
      >
    </div>
  </div>
</template>
<script>
import * as api from "@/api";
export default {
  name: "MessageFeedbackForm",
  props: {
    messageId: {
      type: Number,
      default: 0,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      enumFeedbackType: [
        "答非所问",
        "未理解内容或要求",
        "回答逻辑混乱",
        "有事实性错误",
        "内容不专业或缺乏深度",
        "回答对我没有帮助",
      ],
      formData: {
        feedbackType: [],
        betterSuggested: "",
      },
    };
  },
  created() {},
  methods: {
    submit() {
      if (
        this.formData.feedbackType.length <= 0 &&
        !this.formData.betterSuggested
      ) {
        return false;
      }
      api.msgThumbsDown(this.messageId, this.formData).then((res) => {
        this.$emit("success", this.index);
      });
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="less" scoped>
.feedback-wrap {
  padding: 20px;
  & h3 {
    font-size: 18px;
    text-align: center;
    margin-bottom: 24px;
  }
  & .van-checkbox {
    margin: 10px 0;
  }
  & .van-cell {
    background: #f7f8fc;
    border-radius: 5px;
    padding: 10px;
  }
  & .foot-btn {
    padding-top: 20px;
    text-align: right;
  }
}
@media screen and (min-width: 680px) {
  .feedback-wrap {
    padding: 20px;
    & h3 {
      font-size: 18px;
      margin-bottom: 24px;
    }
    & .van-checkbox {
      margin: 15px 0;
      & :deep(.van-checkbox__icon) {
        font-size: 16px;
      }
      & :deep(.van-checkbox__label) {
        font-size: 16px;
        line-height: 1em;
        margin-left:16px;
      }
    }
    & .van-cell {
      width: 320px;
      border-radius: 5px;
      padding: 10px;
      font-size: 16px;
      line-height: 1.5em;
      & :deep(.van-cell__value) {
        font-size: 16px;
      }
      & :deep(.van-field__control--min-height) {
        min-height: 5em;
      }
    }
    & .foot-btn {
      padding-top: 20px;
      & .van-button {
        font-size: 16px;
        line-height: 2em;
        height: 2em;
        border-radius: 6px;
      }
    }
  }
}
</style>
