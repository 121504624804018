<template>
  <div class="header" v-if="botInfo">
    <div class="header-main" v-if="!isMobile">
      <div class="logo">
        <span>{{ botInfo.botName }}</span
        ><span v-if="botInfo.libraryName" class="library-name"
          >@{{ botInfo.libraryName }}</span
        >
      </div>
      <div class="right">
        <van-popover close-on-click-outside>
          <!-- <van-button type="primary" plain size="mini">退出登录</van-button> -->
          <div class="logout-btn" @click="logout">退出登录</div>
          <template #reference>
            <van-image
              class="avatar"
              round
              :src="userInfo.avatar"
              fill="cover"
            />
            <span class="nickname">{{ userInfo.nickName }}</span>
          </template>
        </van-popover>
      </div>
    </div>
    <template v-else>
      <van-nav-bar
        :title="botInfo.botName"
        @click-right="newSession"
        @click-left="openPopup"
      >
        <template #right> <van-icon name="add-o"></van-icon>新建</template>
        <template #left><van-icon name="user-circle-o" /></template>
      </van-nav-bar>
      <van-popup
        v-model:show="popupShow"
        position="left"
        overlay-class="popup-overlay"
        class="popup"
      >
        <div class="userinfo">
          <van-image
            class="avatar"
            round
            :src="userInfo.avatar"
            fille="cover"
          />
          <div class="nickname">{{ userInfo.nickName }}</div>
        </div>
        <van-button
          type="primary"
          class="new-session-btn"
          round
          icon="plus"
          @click="newSession"
          >新建对话</van-button
        >
        <SessionList
          @changeSession="changeSession"
          :currentSessionId="currentSessionId"
          :lastUpdatedSession="lastUpdatedSession"
        />
      </van-popup>
    </template>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import SessionList from "./SessionList";
export default {
  name: "Header",
  components: { SessionList },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    currentSessionId: {
      type: Number,
      default: 0,
    },
    lastUpdatedSession: {
      sessionId: {
        type: Number,
        default: 0,
      },
      askText: {
        type: String,
        default: "",
      },
    },
  },
  data() {
    return {
      popupShow: false,
    };
  },
  computed: {
    ...mapGetters(["userInfo", "authToken", "botInfo"]),
  },
  created() {},
  methods: {
    ...mapActions(["setCurrentUser", "setToken"]),
    newSession() {
      this.popupShow = false;
      this.$emit("navRightClick");
    },
    changeSession(sessionId) {
      this.popupShow = false;
      this.$emit("changeSession", sessionId);
    },
    openPopup() {
      this.popupShow = !this.popupShow;
    },
    logout() {
      this.setCurrentUser(null);
      this.setToken(null);
      this.$router.push({
        name: "Author",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.header{
  position: relative;
  z-index:1;
}
.van-nav-bar {
  line-height: 46px;
  & :deep(.van-nav-bar__content) {
    height: 46px;
  }
  & .van-icon {
    font-size: 22px;
    padding: 0 6px;
  }
}
.popup {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  &.van-popup--left {
    width: 80%;
    top: 46px;
    bottom: 0;
    transform: translate3d(0, 0, 0);
  }
  &.van-popup-slide-left-enter-from {
    transform: translate3d(-100%, 0, 0);
  }
  &.popup.van-popup-slide-left-leave-active {
    transform: translate3d(-100%, 0, 0);
  }
  & .userinfo {
    text-align: center;
    padding: 20px 16px 0;
    & .avatar {
      width: 40px;
      height: 40px;
      margin-bottom: 4px;
    }
    & .nickname {
      font-size: 14px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  & .new-session-btn {
    display: block;
    margin: 16px;
  }
}
.van-overlay.popup-overlay {
  bottom: 0;
  top: 46px;
}
@media screen and (min-width: 680px) {
  .header {
    &-main {
      height: 60px;
      line-height: 60px;
      background: #f7f8fc;
      // color: #fff;
      display: flex;
      & .logo {
        height: 60px;
        font-size: 22px;
        padding-left: 20px;
        padding-right: 20px;
        width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: bold;
        & .library-name {
          color: #666;
          font-weight: normal;
        }
      }

      & .right {
        flex: 1;
        line-height: 20px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        & :deep(.van-popover__wrapper) {
          display: flex;
          align-items: center;
        }
        & .avatar {
          width: 40px;
          height: 40px;
          margin-right: 10px;
        }
        & .nickname {
          cursor: pointer;
          // color: #fff;
        }
      }
    }
  }
  & .van-popover[data-popper-placement^="bottom"] {
    & :deep(.van-popover__arrow) {
      border-width: 12px;
      margin-top: -12px;
      border-top-width: 0;
    }
    & :deep(.van-popover__content) {
      border-radius: 12px;
    }
    & .logout-btn {
      font-size: 14px;
      line-height: 20px;
      padding: 18px;
      cursor: pointer;
    }
  }
}
</style>
