<template>
  <div class="main-container message-board" v-if="detail">
    <van-nav-bar :title="`${botInfo.libraryName}留言板`" />
    <div v-if="detail" class="message-board-detail">
      <van-cell-group title="联系信息">
        <van-cell title="姓名" :value="detail.contacterName" />
        <van-cell
          title="联系邮箱"
          :value="detail.contacterEmail"
          v-if="detail.contacterEmail"
        />
        <van-cell
          title="联系电话"
          :value="detail.contacterPhone"
          v-if="detail.contacterPhone"
        /> </van-cell-group
      ><van-cell-group title="留言信息">
        <van-cell title="留言时间" :value="detail.createDate" />
        <van-cell title="留言主题" :value="detail.title" class="text-cell" />
        <van-cell title="留言内容" class="text-cell">
          <template v-slot:value
            ><div v-html="detail.content.replace(/\n/g, '<br/>')"></div
          ></template>
        </van-cell>
      </van-cell-group>
      <template v-if="detail.status == 0">
        <van-dialog v-model:show="show" v-if="detail.hasSubscribedMsg == 1">
          <div class="subscribe-result-box">
            <p><van-icon name="success" /></p>
            <p>消息订阅成功！</p>
            <p style="font-size: 14px; padding-top: 18px; color: #999">
              请留意微信消息通知
            </p>
          </div> </van-dialog
        ><van-dialog
          v-model:show="show"
          v-else
          confirm-button-text="重新订阅消息"
          @confirm="subscribeMsg"
        >
          <div class="subscribe-result-box">
            <p><van-icon name="fail" /></p>
            <p>消息订阅失败！</p>
            <p style="font-size: 14px; color: #999; padding-top: 18px">
              请点击下方按钮重新订阅
            </p>
          </div>
        </van-dialog>
      </template>
      <van-cell-group title="留言回复" v-else-if="detail.replyContent">
        <van-cell title="回复时间" :value="detail.lastUpdate" />
        <van-cell title="回复内容" class="text-cell">
          <template v-slot:value
            ><div v-html="detail.replyContent.replace(/\n/g, '<br/>')"></div
          ></template>
        </van-cell>
      </van-cell-group>
    </div>
  </div>
</template>
<script>
import * as api from "@/api";
import { mapGetters } from "vuex";
import { getUrlCode } from "@/utils/utils";
export default {
  name: "MessageBoardDetail",
  components: {},
  data() {
    return {
      id: 0,
      detail: null,
      subccribeUrl: "",
      show: true,
    };
  },
  computed: {
    ...mapGetters(["botInfo"]),
  },
  created() {
    this.id = this.$route.query.id;
    this.getData();
  },
  methods: {
    getData() {
      api.getMessageBoardDetail(this.id).then((res) => {
        let detail = res.data;
        if (detail.createDate) {
          detail.createDate = this.$moment(detail.createDate).format(
            "YYYY-MM-DD HH:mm"
          );
        }
        if (detail.lastUpdate) {
          detail.lastUpdate = this.$moment(detail.lastUpdate).format(
            "YYYY-MM-DD HH:mm"
          );
        }
        this.detail = detail;
        this.subccribeUrl = res.subccribeUrl;
      });
    },
    subscribeMsg() {
      window.location.replace(this.subccribeUrl);
    },
  },
};
</script>
<style lang="less" scoped>
.message-board {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  & .message-board-detail {
    flex: 1;
    overflow-y: auto;
    .text-cell.van-cell {
      display: block;
      & :deep(.van-cell__value) {
        text-align: left;
      }
    }
    .subscribe-result-box {
      text-align: center;
      padding: 40px 20px;
      font-size: 18px;
      & .van-icon {
        font-size: 48px;
        margin-bottom: 20px;
      }
      & .van-icon-success {
        color: #07c160;
      }
      & .van-icon-fail {
        color: #f44;
      }
    }
  }
}
</style>
