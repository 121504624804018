<template>
  <div class="session-list">
    <div
      class="session-item"
      v-for="item in sessions"
      :key="'session_' + item.id"
      @click="changeSession(item.id)"
      :class="currentSessionId == item.id ? 'current' : ''"
    >
      <van-icon name="chat-o" />
      <div class="session-item-summary">
        {{ item.lastChatHistory.askText }}
        <!-- <div class="action">
            <el-button
              type="text"
              icon="el-icon-edit"
              @click.stop="
                editSessionSummaryFormData = {
                  sessionId: item.id,
                  summary: item.summary,
                }
              "
            ></el-button>
            <el-button
              type="text"
              icon="el-icon-delete"
              v-popover:popover
              @click.stop="confirmDelSession($event, item.id)"
            ></el-button>
          </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import * as api from "@/api";
export default {
  name: "SessionList",
  props: {
    currentSessionId: {
      type: Number,
      default: 0,
    },
    lastUpdatedSession: {
      sessionId: {
        type: Number,
        default: 0,
      },
      askText: {
        type: String,
        default: "",
      },
    },
  },
  watch: {
    lastUpdatedSession: function (newValue, oldValue) {
      let sessions = [...this.sessions];
      let index = sessions.findIndex((item) => item.id === newValue.sessionId);
      if (index > -1) {
        sessions[index].lastChatHistory.askText = newValue.askText;
      } else {
        sessions.unshift({
          id: newValue.sessionId,
          lastChatHistory: {
            askText: newValue.askText,
          },
        });
      }
      this.sessions = [...sessions];
    },
  },
  data() {
    return {
      sessions: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      api.getLastChatSessions().then((res) => {
        this.sessions = res.data;
      });
    },
    changeSession(sessionId) {
      this.$emit("changeSession", sessionId);
    },
  },
};
</script>
<style lang="less" scoped>
.session-list {
  border-top: 1px solid #f3f3f3;
  box-sizing: border-box;
  height: calc(100% - 64px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  // touch-action: pan-y;
  overscroll-behavior: none;
  touch-action: manipulation;
  padding: 0 16px;
  & .session-item {
    position: relative;
    padding: 10px 20px 10px 30px;
    // color: #fff;
    border-bottom: 1px solid #f3f3f3;
    cursor: pointer;
    & .van-icon-chat-o {
      position: absolute;
      left: 8px;
      top: 12px;
    }
    &-summary {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-date {
      font-size: 10px;
      color: #999;
    }
    & .edit-session-summary-area {
      display: flex;
      & .el-input {
        flex: 1;
        border-bottom: 1px solid #999;
        & :deep(.el-input__inner) {
          background: transparent;
          color: #fff;
          border: none;
          line-height: 19px;
          height: 19px;
          padding: 0;
        }
      }
      & .el-button.el-button--mini {
        padding: 0;
      }
    }
    &.current {
      background: #efefef;
    }
    & .action {
      position: absolute;
      right: 4px;
      top: 2px;
      display: none;
    }
    &:hover {
      & .session-item-summary {
        padding-right: 50px;
      }
      & .action {
        display: block;
      }
    }
  }
}

@media screen and (min-width: 680px) {
  .session-list {
    border-top: 1px solid #f3f3f3;
    box-sizing: border-box;
    height: calc(100% - 64px);
    overflow-y: auto;
    padding: 0 16px;
    & .session-item {
      position: relative;
      padding: 10px 20px 10px 30px;
      // color: #fff;
      border-bottom: 1px solid #f3f3f3;
      cursor: pointer;
      & .van-icon-chat-o {
        position: absolute;
        left: 8px;
        top: 12px;
      }
      &-summary {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &-date {
        font-size: 10px;
        color: #999;
      }
      & .edit-session-summary-area {
        display: flex;
        & .el-input {
          flex: 1;
          border-bottom: 1px solid #999;
          & :deep(.el-input__inner) {
            background: transparent;
            color: #fff;
            border: none;
            line-height: 19px;
            height: 19px;
            padding: 0;
          }
        }
        & .el-button.el-button--mini {
          padding: 0;
        }
      }
      &.current {
        background: #efefef;
      }
      & .action {
        position: absolute;
        right: 4px;
        top: 2px;
        display: none;
      }
      &:hover {
        & .session-item-summary {
          padding-right: 50px;
        }
        & .action {
          display: block;
        }
      }
    }
  }
}
</style>
