import {
  SETCURRENTUSER,
  SETTOKEN,
  SETBEFORELOGIN,
  SETBOTCODE,
  SETBOTINFO
} from './type.js'

const state = {
  userInfo: null,
  authToken: null,
  beforeLogin: null,
  botCode: null,
  botInfo: null,
}
const mutations = {
  [SETCURRENTUSER](state, userInfo) {
    state.userInfo = userInfo
    if (userInfo == null) {
      window.localStorage.removeItem('current_user')
    } else {
      window.localStorage.current_user = JSON.stringify(userInfo)
    }
  },
  [SETTOKEN](state, token) {
    state.authToken = token
    if (token == null) {
      window.localStorage.removeItem('auth_token')
    } else {
      state.authToken.refresh_token_expires = (new Date()).valueOf() + token.refresh_token_expires_in * 1000
      window.localStorage.auth_token = JSON.stringify(token)
    }
  },
  [SETBEFORELOGIN](state, beforeLogin) {
    if (beforeLogin == null) {
      window.sessionStorage.removeItem('before_login')
    } else {
      window.sessionStorage.setItem('before_login', JSON.stringify(beforeLogin))
    }
    state.beforeLogin = beforeLogin
  },
  [SETBOTCODE](state, botCode) {
    state.botCode = botCode
    if (botCode == null) {
      window.localStorage.removeItem('bot_code')
    } else {
      window.localStorage.setItem('bot_code', botCode)
    }
  },
  [SETBOTINFO](state, botInfo) {
    // if (botInfo == null) {
    //   window.localStorage.removeItem('bot_info')
    // } else {
    //   window.localStorage.setItem('bot_info', JSON.stringify(botInfo))
    // }
    state.botInfo = botInfo
  },
}

const getters = {
  userInfo(state) {
    let userInfo = state.userInfo
    if (userInfo == null) {
      userInfo = (window.localStorage.current_user || null) ? JSON.parse(window.localStorage.current_user) : null
    }
    if (userInfo == null) {
      //未登陆
      return null
    } else {
      return userInfo
    }
  },
  authToken(state) {
    let authToken = state.authToken
    if (authToken == null) {
      authToken = (window.localStorage.auth_token || null) ? JSON.parse(window.localStorage.auth_token) : null
    }
    return authToken

  },
  beforeLogin(state) {
    console.log('beforeLogin', state.beforeLogin)
    let _beforeLogin = state.beforeLogin
    if (_beforeLogin == null) {
      _beforeLogin = (window.sessionStorage.before_login || null) ? JSON.parse(window.sessionStorage.before_login) : null
    }
    return _beforeLogin
  },
  botCode(state) {
    let botCode = state.botCode
    if (!botCode) {
      botCode = (window.localStorage.bot_code || null) ? window.localStorage.bot_code : null
    }
    return botCode
  },
  botInfo(state) {
    let botInfo = state.botInfo
    // if (botInfo == null) {
    //   botInfo = (window.localStorage.bot_info || null) ? JSON.parse(window.localStorage.bot_info) : null
    // }
    return botInfo
  },
}

export default {
  state, mutations, getters
}
