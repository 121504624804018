<template>
  <div class="page-container">404 page not found</div>
</template>
<script>
export default {
  name: "nofoundpage",
  data() {
    return {
    };
  },
};
</script>
<style scoped>
.page-container {
  font-size: 20px;
  text-align: center;
  color: rgb(192, 204, 218);
}
</style>
