import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Vuex from 'vuex'
import { Button, Form, Field, Dialog, Loading, Popover, Image as VanImage, Popup, Icon, NavBar, Checkbox, CheckboxGroup, Cell, CellGroup } from 'vant'
import 'vant/lib/index.css'
import 'vant/lib/icon/index.css'
import store from '@/store/store'
import moment from 'moment'

const app = createApp(App)
app
.use(store)
.use(router)
.use(Vuex)
.use(Button)
.use(Form)
.use(Field)
.use(Dialog)
.use(Loading)
.use(Popover)
.use(VanImage)
.use(Popup)
.use(Icon)
.use(NavBar)
.use(Checkbox)
.use(CheckboxGroup)
.use(Cell)
.use(CellGroup)
app.config.globalProperties.$moment = moment
// 挂载全局方法
// const goBeforeLoginUrl = () => {
//   if (!store.getters.beforeLogin || store.getters.beforeLogin.path.indexOf('/author') != -1) {
//     router.push('/')
//   } else {
//     router.push({ path: store.getters.beforeLogin.path, query: store.getters.beforeLogin.query })
//   }
//   store.dispatch('setBeforeLogin', null)
// }
// // 将globalFunc方法挂载在全局
// app.provide('goBeforeLoginUrl', goBeforeLoginUrl);
router.beforeEach((to, from, next) => {
  console.log(`to:`, to)
  if (to.meta.title) {
    // document.title = to.meta.title
  }

  // if (to.path == '/author' && store.getters.loginStatus > 0) {
  //     console.log('用户使用后退返回到授权页，则默认回到首页')
  //         // 用户使用后退返回到授权页，则默认回到首页
  //     next('/')
  //     return false
  // }

  if (to.path != '/author' && to.path != '/404' && (store.getters.userInfo == null || store.getters.authToken == null)) {
    if (to.path != '/') {
      store.dispatch('setBeforeLogin', {
        path: to.path,
        query: to.query
      })
    }
    next('/author')
    return false
  }
  next()
})
app.mount('#app')
