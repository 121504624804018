import axios from 'axios';
import router from '../router'
import store from "../store/store.js";
import { showToast, showFailToast, showLoadingToast, closeToast } from 'vant';
import config from '../config/config'

// 请求延时
axios.defaults.timeout = 30000
axios.defaults.baseURL = config.apiBaseUrl

axios.defaults.headers = {
  "Access-Control-Allow-Origin": "*", // 设置跨域头部
  'Content-Type': 'application/json',
}

axios.interceptors.request.use(
  config => {
    // config.data = JSON.stringify(config.data)
    let loadingflag = config.loadingflag === undefined ? true : config.loadingflag;
    let headers = config.headers
    let auth_token = store.getters.authToken ? store.getters.authToken.auth_token : ""
    let bot_code = store.getters.botCode ? store.getters.botCode : ""
    headers['Authorization'] = 'Bearer ' + auth_token
    headers['bot_code'] = bot_code
    config.headers = headers
    if (loadingflag) {
      showLoadingToast({
        duration: 0,
        message: '加载中...',
        forbidClick: true,
        className: 'custom-loading-class',
      });
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

// http response 拦截器
axios.interceptors.response.use(
  response => {
    closeToast()
    return response
  },
  error => {
    // 超时请求处理
    var originalRequest = error.config;
    if (error.code == 'ECONNABORTED' && error.message.indexOf('timeout') != -1 && !originalRequest._retry) {
      // showFailToast('请求超时！');
      showToast({
        message: "请求超时！",
        className: "custom-toast-text",
      });
      originalRequest._retry = true
      return Promise.reject(error);
    }
    if (error.response) {
      if (error.response.status == 401) {
        let _token = store.getters.authToken
        if (_token != null) {
          return axios.post('WebLogin/RefreshToken', { userId: store.getters.userInfo.userId, refreshToken: _token.refresh_token }).then((res) => {
            store.dispatch("setToken", res.data);
            console.log(store.getters.authToken)
            error.config.__isRetryRequest = true;
            error.config.headers.Authorization = 'Bearer ' + res.data.auth_token;
            return axios(error.config);

          });
        } else {
          // 刷新token失败 清除token信息并跳转到登录页面
          closeToast()
          toLogin()
          return null
        }
      }
      // 403 无权限
      else if (error.response.status == 403) {
        closeToast();
        toLogin()
        return null;
      } else {
        if (error.response.data && error.response.data.message) {
          showToast({
            message: error.response.data.message,
            className: "custom-toast-text",
          });
          return Promise.reject(error);
        }
      }
    }
    showToast({
      message: "远程服务器通讯失败",
      className: "custom-toast-text",
    });
    return Promise.reject(error);
  }
);
const toLogin = () => {
  window.sessionStorage.beforeLoginUrl = router.currentRoute.path
  window.sessionStorage.beforeLoginQuery = JSON.stringify(router.currentRoute.query)
  store.dispatch("setCurrentUser", null)
  store.dispatch("setToken", null)
  store.dispatch("setBotInfo", null);
  window.location.reload();
};
export default axios
